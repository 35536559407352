<template>
  <div>
    <Footer></Footer>
      <div class="content">
        
        <span class="title">
          <!-- 第九届“大唐杯”全国大学生新一代信息通信技术大赛（南区）全国总决赛成功举办 -->
          平台介绍
          <p class="titext">
            <!--<span>学唐小助手</span>
            &emsp;&emsp;&emsp;&emsp;&emsp;
            <span>发布于2022-07-19</span>-->
          </p>
        </span>

        <div class="imgbox">
          <!-- <img  style="width:100%;height: 100%;" src="./Platform_img/img_A.jpg" alt="">  -->
        </div>

        <div class="contbox">
            <div class="item_img">
              <img src="@/assets/images/xt-1.jpg" />
            </div>
          <p class="text"> 1.学唐平台</p>
          <p class="text_t"> 学唐Online平台与小唐人虚拟仿真实践平台共同构成了在线课程与考试练习、虚拟仿真实践，智慧教学系统以及技术课程资源相融合的信息化竞赛教学支撑体系。</p>
          <p class="text_t">学唐平台为中信科移动培训中心自有线上学习平台，可在移动端和网页端进行使用。平台运用高等教育教学改革新理念和信息化教学新要求设计教学方案、组织教学资源和实施教学过程；准确把握所授课程的教学要求，充分挖掘和利用课程数字化资源的育人因素，教学目标设置符合认知规律；充分利用信息化工具与资源改革课堂教学，破解教学难题，增强教学效果；能合理运用智慧教育环境中的人工智能或数据分析技术引导控制学习进程，有效调动学生自主学习与协作探究的积极性。</p>
          <p class="text_t">大唐杯自2014年举办以来，参赛人数累计突破10万，覆盖全国400余所高校，培养双师型教师数千名。大赛设立省份选拔赛和全国总决赛两个赛程。面向双一流重点院校，普通本科院校，职业院校，一带一路国际合作院校分别设置研究生、本科、高职、一带一路、教师等不同赛道组别。实现了产业人才链培养全覆盖。大赛内容以信息通信技术为核心，融合大数据、人工智能等跨学科技术，引入智能网联汽车、智慧电网、物联网应用等垂直行业创新应用案例。实现了复合型人才培养综合发展。大赛以移动通信关键技术学习为基础，创建了产业认知、专业理论学习、虚拟仿真练习、真设备工程实践、工程应用创新、项目管理实践的人才培养体系。大唐杯促进了教育链、人才链、产业链和创新链的有效连接。</p>
          <p class="text_t">学唐平台目前拥有技术类核心产权课程170余门，技术类文档与经验积累分享1000余个，其中线上视频课程累计350余课时，内容包括技术及行业认知类，信息通信基础理论课程，信息通信类专业课程，信息通信实操课程；覆盖4G/5G网络技术，系统技术，大数据，人工智能，工程实践，工程概论，工程项目管理，开发设计流程原理，经济决策分析原理等，并拥有1000余门其他技术管理与行政类职场通识课程，能满足各类教学与竞赛培训需求。学唐平台技术课程资源由中信科移动培训中心50余人的资深讲师团队开发，其中博士学历7人，硕士学历21人，本科学历17人；拥有一线工程师与研发经验的高级讲师和金牌讲师共30余人，同时与高校合作组建了20余名外聘专家名师团队，做到了教学理念先进，技术运用合理，较好实现信息技术与课程教学的深度融合，无知识产权异议和纠纷； 课程资源能够完整体现所授课程的主要内容，有效破解教学中的重点难点问题，交互性能和教学效果好；能够合理选用多媒体呈现教学内容，界面布局和色彩搭配协调，播放顺畅稳定，导航链接准确，用户体验良好，能同时承受高达十万用户级别的课程观看与考试并发；课程平台能兼容内容资源，留存教学数据，辅助线上线下混合教学，有效支持智慧教学决策。 学唐平台2022年第一季度活跃16万人次，累计完成30余万的学习小时，是融合了线上培训，考核答辩，讲师答疑，分享传递、技术论坛等功能的综合性线上培训平台，同时也是竞赛的理论赛支撑平台。</p>
          <div class="item_img">
              <img src="@/assets/images/fz-1.jpg" />
            </div>
          <p class="text_c">2.云网工程实践仿真平台（小唐人仿真实践平台）</p>
          <p class="text_t">云网工程实践仿真平台作为虚实结合的新一代工程实践教学理念平台，承担了大唐杯大赛的仿真实践赛项，由中信科移动通信技术有限公司培训中心自主开发：云网工程实践仿真平台采用分布式架构设计，通过完善的知识体系构建、开放式的管理模式；打造的虚拟仿真教学云网络，各服务节点高校可以通过虚拟仿真网络，做到教学资源共建、教学经验共享、科研课题共创的全新开放式教学平台；同时搭建云端与硬件设备远程控制，通过共享硬件教学模式，为高校教育质量提升、教师的教学理念更新、学生的学习兴趣激发，学科竞赛提供了有力的支撑；</p>
          <p class="text_t">平台融合创新特色的5G技术原理实践，将新一代通信技术原理内容以实践模块化形式进行展现，同时在教学过程中，打破传统的我教你学的方式，开展以实际行动为导向的教学方法，在教学过程中，重点倡导“边学边练”的学习理念，加强创设真实的实践环境，依托仿真平台，强调探究性学习、互动学习、协作学习等多种学习策略。充分利用企业在行业内的经验以及优势，促进学生职业能力的培养，有效的培养了学生解决问题的能力以及可持续发展的能力；</p>
          <p class="text_t">平台支持5G电信工程实践、网络运维实践、规划与优化实践教学，并采用“虚实结合、理实互通”的教学方式，即降低实践教学成本、提高实践教学效率，又通过5G工程实践虚拟化，让学生以实践形式加深对工程及运维知识的理解，同时授课教师可以自行设定授课环节、根据学生的学习进度设定不同的任务进行练习，定制化的为每一位同学提供完善的教育教学服务；</p>
          <p class="text_t">平台紧随行业复合型人才所需，构建端到端的网络部署，融合行业特色应用仿真，将5G真实网络与大数据、人工智能、智能网联汽车技术融合，提供智能网联汽车、人工智能机器人、5G+VR、5G+全息影像、智能KPI网络优化、人工智能小车等虚实结合的教学实践内容，创新实践教学内容，促进“教育链-产业链-人才链”相融通。</p>
          <div class="item_img">
              <img src="@/assets/images/zs-1.jpg" />
            </div>
          <p class="text_c">3.真设备竞赛平台（行业运营商网络真实环境）</p>
          <p class="text_t">“大唐杯”全国大学生新一代信息通信技术大赛在全国总决赛阶段，以行业真实设备及真实应用场景为竞赛平台，构造运营商信息通信网络中的真实应用案例，围绕真场景中的关键技术和痛点问题进行技术技能创新。</p>
          <p class="text_t">大赛以中信科移动面向运营商交付的真实设备作为全国赛的竞赛平台，搭建起从5G核心网、5G基站、5G射频天线、商用终端（模组）到车联网应用、智慧电网应用等垂直行业应用的真实网络场景。以信息通信关键技术、人工智能、大数据、计算等专业技术相互融合的工程复杂问题案例为竞赛内容。通过竞赛过程培养教师在真场景、真问题、真设备、真网络环境下的工程实践能力。</p>
          <div class="img_box">
          </div>
        </div>
      </div>
  <Bottom></Bottom>
  </div>
</template>
<script>
import Footer from '@/components/Footer/Footer.vue'
import Bottom from '@/components/Bottom/Bottom.vue'
import $ from "jquery";
  export default {
    name: 'Home',
    components: {
        Footer,
        Bottom
    },
    data(){
      return {

      }
    },
    methods:{
    },
    mounted(){

    },
    created(){

    },
  }
  </script>
  
  <style scoped lang="scss">

  @import './Platform.scss';
  </style>
  